import { Component, Vue } from 'vue-property-decorator';
import isMobile from 'ismobilejs';
import bootbox from 'bootbox';
import type { NavigationGuardNext, Route } from 'vue-router';
import { getHrefFromElement } from '@app/utils/get-href-from-element';
import { changeLocation } from '@app/utils/change-location';

@Component
export default class WithBeforeUnload extends Vue {
  customBeforeUnloadMessage!: string;
  formHasChanges!: boolean;
  shouldPreventNavigation!: boolean;

  get isIos() {
    return isMobile(window.navigator).apple.device;
  }

  beforeRouteLeaveHelper(to: Route, from: Route, next: NavigationGuardNext): void {
    if (this.shouldPreventNavigation && this.formHasChanges && to.params?.ignoreRouteLeaveGuard !== 'true') {
      changeLocation(to.fullPath);
    } else {
      next();
    }
  }

  bootboxConfirm(resolve: () => void, reject?: () => void) {
    bootbox.confirm({
      backdrop: false,
      size: 'small',
      message: this.customBeforeUnloadMessage,
      buttons: {
        confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
        cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
      },
      callback: (result: boolean) => {
        if (result) {
          resolve();
        } else {
          if (reject) reject();
        }
      },
    });
  }

  attachListeners() {
    if (this.isIos) {
      const header = document.querySelector('header.header') as HTMLElement;
      const sideMenu = document.querySelector('section.side-menu') as HTMLElement;

      // flip the order of the listeners to make sure the header listener is called first
      header && header.addEventListener('click', this.handleIosSafariLinkClick, true);
      sideMenu && sideMenu.addEventListener('click', this.handleIosSafariLinkClick);
    } else {
      window.onbeforeunload = () => {
        if (this.shouldPreventNavigation && this.formHasChanges) return this.customBeforeUnloadMessage;
        return null;
      };
    }
  }

  removeListeners() {
    if (this.isIos) {
      const header = document.querySelector('header.header') as HTMLElement;
      const sideMenu = document.querySelector('section.side-menu') as HTMLElement;

      header && header.removeEventListener('click', this.handleIosSafariLinkClick);
      sideMenu && sideMenu.removeEventListener('click', this.handleIosSafariLinkClick);
    } else {
      window.onbeforeunload = null;
    }
  }

  handleIosSafariLinkClick(event: MouseEvent) {
    const clickedElement = event?.target as HTMLElement;
    const parentLinkElement = clickedElement?.parentElement as HTMLElement;
    const href = getHrefFromElement(clickedElement) || getHrefFromElement(parentLinkElement);

    if (this.shouldPreventNavigation && this.formHasChanges && href) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.bootboxConfirm(() => changeLocation(href));
    }
  }

  mounted() {
    this.attachListeners();
  }

  beforeDestroy() {
    this.removeListeners();
  }
}
