
  import { groupBy, sortBy } from 'lodash';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import SubFormResponseBlock from './sub-form-response-block.vue';
  import type { SubFormCompletionShowOnly } from './utils';
  import type { BaseRecord } from '@app/models/module-record';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import type { SubFormResponse } from '@app/models/sub-form-response';
  import type { SubFormSection } from '@app/models/sub-form-section';

  @Component({ components: { SubFormResponseBlock } })
  export default class SubFormCompletionShow extends Vue {
    @Prop(Object) readonly record!: Pick<BaseRecord, 'id' | 'location_id'>;
    @Prop(Boolean) readonly printing?: boolean;
    @Prop(Object) readonly completion!: Pick<SubFormCompletion, SubFormCompletionShowOnly>;
    @Prop({ type: Boolean, default: false }) readonly modal!: boolean;
    @Prop(Boolean) readonly sidebarMode?: boolean;

    get nonHiddenResponses(): SubFormResponse[] {
      return this.completion.sub_form_responses.filter(
        (r) => r?.sub_form_question?.config?.read_view_mode !== 'hidden' && !!r?.sub_form_question?.active
      );
    }

    get responsesOrderedBySection(): { responses: SubFormResponse[]; section?: SubFormSection }[] {
      return Object.values(groupBy(this.nonHiddenResponses, (r) => r.sub_form_question?.sub_form_section?.absolute_index)).reduce(
        (acc, responses) => {
          return [
            ...acc,
            {
              responses: sortBy(responses, (r) => r.sub_form_question?.index),
              section: responses?.[0]?.sub_form_question?.sub_form_section,
            },
          ];
        },
        [] as { responses: SubFormResponse[]; section?: SubFormSection }[]
      );
    }
  }
