
  import SubFormResponseOutput from '@app/components/sub-form-completion/sub-form-response-output.vue';
  import { Component, Prop, Mixins } from 'vue-property-decorator';
  import { camelCase, upperFirst, isEmpty, sortBy } from 'lodash';
  import { isArrayTypeQuestion } from '@app/services/model-helpers';
  import WithRelatedObjects from '@app/mixins/with-related-objects';
  import type { BaseRecord } from '@app/models/module-record';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import type { SubFormResponse } from '@app/models/sub-form-response';
  import { FieldType } from '@app/models/sub-form-question';
  import type { BaseQuestionOptions } from '@app/models/question-options/base-question-options';

  import SubFormResponseBlockAttachment from './sub-form-response-block-attachment.vue';

  @Component({ components: { SubFormResponseOutput, SubFormResponseBlockAttachment } })
  export default class SubFormResponseBlock extends Mixins(WithRelatedObjects) {
    @Prop(Object) readonly completion!: SubFormCompletion;
    @Prop(Boolean) readonly modal!: boolean;
    @Prop(Boolean) readonly printing?: boolean;
    @Prop(Object) readonly record!: Pick<BaseRecord, 'id' | 'location_id'>;
    @Prop(Boolean) readonly sidebarMode?: Boolean;

    get componentName(): string {
      return `${upperFirst(camelCase(this.response.sub_form_question_field_type))}Display`;
    }

    get sortedAttachments() {
      return sortBy(this.relatedAttachments, ['is_image']);
    }

    get hiddenQuestion(): boolean {
      return this.response?.sub_form_question?.config?.hide_question === 'true';
    }

    get noResponse(): boolean {
      if (!this.response.sub_form_question) {
        return true;
      }

      if (this.response.sub_form_question_field_type === FieldType.toocs) {
        return !Object.values(this.response.response).filter((val) => !!val && !!val?.['0']).length;
      }

      return isArrayTypeQuestion(this.response.sub_form_question)
        ? !isEmpty(this.response.response)
        : !Object.values(this.response.response).filter((value) => isEmpty(value)).length;
    }

    get questionTitle() {
      const { question, short_title } = this.response.sub_form_question || {};
      return this.$adminSanitize(short_title || question || '');
    }

    get showAnswer(): boolean {
      if (isEmpty(this.response) || isEmpty(this.response.sub_form_question)) {
        return false;
      }

      return !!this.response.sub_form_question?.active || !this.noResponse;
    }

    get showDescription(): boolean {
      return (
        !!this.response.sub_form_question?.description && this.response.sub_form_question?.config.read_view_mode === 'show_description'
      );
    }

    horizontalDividerMode(response: SubFormResponse, dividerMode: BaseQuestionOptions['horizontal_divider']): boolean {
      return response?.sub_form_question?.config.horizontal_divider === dividerMode;
    }
  }
