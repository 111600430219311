import { Component } from 'vue-property-decorator';
import WithBeforeUnload from './with-before-unload';
import I18n from '@app/i18n';

@Component
export default class WithCompletionUnsaved extends WithBeforeUnload {
  formHasChanges = false;
  formHasNewUnsavedResponse = false;
  customBeforeUnloadMessage = I18n.t('app.labels.unsaved_changes_warning');
  shouldPreventNavigation = true;

  onFormHasChanges({ hasChanges, hasNewUnsavedResponse }: { hasChanges: boolean; hasNewUnsavedResponse: boolean }) {
    this.formHasChanges = hasChanges;
    this.formHasNewUnsavedResponse = hasNewUnsavedResponse;
  }
}
