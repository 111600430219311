import type { AxiosResponse } from 'axios';
import I18n from '../i18n';
import { toaster } from './toaster';
import ensureRollbar from '@app/services/rollbar';

export const onRecordSubmitError = (data: AxiosResponse['data'], overrideMessage?: string) => {
  let message;
  if (data?.error?.startsWith('New workflow step is invalid. Please check available steps for the current workflow')) {
    message = I18n.t('controllers.concerns.sub_form_completion_builder.forbidden_workflow');
  }

  ensureRollbar()?.error(data);

  toaster({
    text: overrideMessage || message || data?.error || I18n.t('app.labels.something_went_wrong'),
    position: 'top-right',
    icon: 'error',
  });
};
