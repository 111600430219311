import type { ModuleName } from '@app/models/module-name';
import type { SubForm } from '@app/models/sub-form';

export const displayModuleName = (
  moduleName: string,
  subForm: Nullable<SubForm> = null,
  moduleNamesByName: Record<string, Pick<ModuleName, 'display'>>,
  $t: (key: string) => string
): string => {
  switch (moduleName) {
    case 'Action':
      return $t('app.labels.action');
    case 'MedicalCertificate':
      return $t('app.labels.medical_certificate');
    case 'IncidentParticipant':
      return $t('app.labels.incident_participant');
    case 'ContactUser':
      return $t('app.labels.contact_user');
    case 'TenantUser':
      return $t('app.labels.user');
    case 'MedicalUser':
      return $t('app.labels.medical_user');
    case 'VisitorUser':
      return $t('app.labels.visitor_user');
    case 'ContractorUser':
      return $t('app.labels.contractor_user');
    case 'RecordRelation':
      return $t('app.labels.record_relation');
    case 'RegulatoryReport':
      return $t('app.labels.regulatory_report');
    case 'UserInvolvement':
      return $t('app.labels.involvement');
    case 'Document':
      return moduleName;
    case 'SubFormCompletion':
      return subForm?.title || 'Sub Form';
    case 'Activity':
      return $t('app.labels.action');
    default:
      return moduleNamesByName[moduleName]?.display;
  }
};
