
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { isInfected } from '@app/services/model-helpers';
  import type { Attachment } from '@app/models/attachment';
  import type { AsfAttachmentOnly } from '@app/components/admin/sub-forms/utils';

  @Component({ methods: { isInfected } })
  export default class SubFormResponseBlockAttachment extends Vue {
    @Prop(Object) readonly attachment!: Pick<Attachment, AsfAttachmentOnly>;
    @Prop(Boolean) readonly printing!: boolean;
  }
