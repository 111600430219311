import type { SubFormResponse } from '@app/models/sub-form-response';
import { Component, Vue } from 'vue-property-decorator';
import type { SubFormCompletion } from '@app/models/sub-form-completion';
import type { ResponseValue } from '@app/models/sub-form-question';

export type SubFormDataValue = { id: SubFormResponse['id']; response: ResponseValue };
export type SubFormDataAndId = Partial<Record<string, SubFormDataValue>>;

@Component
export class WithPreviousCompletionForm extends Vue {
  previousCompletionForm: Nullable<SubFormDataAndId> = null;

  getPreviousForm(completion: Pick<SubFormCompletion, 'sub_form_responses'>): SubFormDataAndId {
    return completion.sub_form_responses?.reduce((acc, response) => {
      return { ...acc, [response.sub_form_question_id]: { response: response.response, id: response.id } };
    }, {} as SubFormDataAndId);
  }
}
